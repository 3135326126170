
import process from "process";

import { ApiList } from "../../config/apiList";
import moment from "moment";
import { config } from "../../config/contants";

export const formatter = (type, value) => {
  if (type === "spend") {
    return currencyFormatter(value);
  } else if (type === "airtime") {
    return timeFormatter(value);
  } else {
    return countFormatter(value);
  }
};

export const setGradient = (
  start = "#C54178",
  mid,
  end = "#4A569D",
  dir = "right"
) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  let gradient = ctx.createLinearGradient(0, 0, 200, 0);
  if (dir === "down") {
    gradient = ctx.createLinearGradient(0, 0, 0, 200);
  }
  gradient.addColorStop(0, end);
  if (mid) gradient.addColorStop(0.5, mid);
  gradient.addColorStop(1, start);
  return gradient;
};
export function convertHexToRGBA(hex, opacity) {
  const tempHex = hex.replace("#", "").replace(/\s/g, "");
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${opacity / 100})`;
}

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const LightenDarkenColor = (col, amt) => {
  var usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

const currencyFormatterIndia = (currency) => {
  const spent = parseFloat(currency);

  if (spent >= 10000000) {
    //crore
    const n = spent / 10000000;
    return "₹ " + Math.abs(n.toFixed(2)) + "Cr";
  }

  if (spent >= 100000 && spent < 10000000) {
    //lakh
    const n = spent / 100000;
    return "₹ " + Math.abs(n.toFixed(2)) + "L";
  }

  if (spent >= 10000 && spent < 100000) {
    //thousand
    const n = spent / 10000;
    return "₹ " + Math.abs(n.toFixed(2)) + "K";
  }

  return "₹ " + currency;
}

const OneMillion = 1000000
const OneBillion = 1000 * OneMillion

const currencyFormatterIndonesia = (currency) => {
  const spent = parseFloat(currency);

  if (spent >= OneBillion) {
    const n = spent / OneBillion;
    return "IDR " + Math.abs(n.toFixed(2)) + "B";
  }

  if (spent >= OneMillion) {
    const n = spent / OneMillion;
    return "IDR " + Math.abs(n.toFixed(2)) + "M";
  }

  return "IDR " + currency
}

export const currencyFormatter = (currency) => {
  switch (getCountry()) {
    case 'India':
      return currencyFormatterIndia(currency)
    case 'Indonesia':
      return currencyFormatterIndonesia(currency)
    default:
      return currencyFormatterIndia(currency)
  }
};

export function timeFormatter(time) {
  let h = Math.floor(time / 3600);
  let m = Math.floor((time % 3600) / 60);
  let result = 0;
  if (h > 0 && m === 0) {
    result = h + "h";
  } else if (h === 0 && m > 0) {
    result = m + "m";
  } else {
    result = h + "h " + m + "m";
  }
  return result;
}

export function countFormatter(count, d) {
  let playout = parseInt(count);
  let display = 0;
  d = d || 1000;
  if (playout >= d) {
    playout = Math.abs((playout / d).toFixed(2));
    display = playout + "K";
  } else {
    display = count;
  }
  return display;
}

export const fileResizer = (image, callback, MAX_WIDTH, MAX_HEIGHT) => {
  let canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");
  var img = new Image();
  img.src = image;
  img.onload = () => {
    ctx.drawImage(img, 0, 0);
    MAX_WIDTH = MAX_WIDTH || 800;
    MAX_HEIGHT = MAX_HEIGHT || 600;
    var width = img.width;
    var height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;
    ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    canvas.toBlob((blob) => {
      callback(blob, canvas.toDataURL());
    });
  };
  img.onerror = (e) => {
    console.log("error", e);
  };
};

export const getMapsKey = () => {
  const keys = {
    production: "AIzaSyDQ1haWxPZw4T25hbMxAPLcRfOQWnwPCl8",
    development: "AIzaSyBtu5OG-j2lEAqruqt2Fa0Er4a--xuOk7g",
  };
  const development =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  return development ? keys.development : keys.production;
};

const paramFilteredKeys = [
  "sortBy",
  "since",
  "till"
]

export const getParams = (props = null) => {
  const inputDateRange = dateRange()

  const inputData = { ...props, ...inputDateRange }

  const filtered = Object.keys(inputData)
    .filter((key) => paramFilteredKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = decodeURIComponent(inputData[key]);
      return obj;
    }, {});

  return new URLSearchParams(filtered).toString();
};

export const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const hasValidDateLimit = () => {
  const limit = JSON.parse(localStorage.getItem(config.DATE_LIMIT_KEY)) || {}
  return 'minimum' in limit && moment(limit.validUpto).isAfter(moment())
}

export const setCountry = async (country) => {
  localStorage.removeItem(config.DATE_LIMIT_KEY)
  localStorage.removeItem(config.DATE_DATA_KEY)
  localStorage.setItem(config.COUNTRY_KEY, country)
  await fetchDateLimit()
  window.location.href = defHomeRedir(true)
}

export const getCountry = () => {
  return localStorage.getItem(config.COUNTRY_KEY) || 'India'
}

export const fetchDateLimit = async () => {
  const response = await fetch(ApiList.env + ApiList.endpoint.info + `?country=${getCountry()}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
  })

  const result = await response.json()
  result.validUpto = moment().add(1, 'hour').toDate()

  if (result.minimum === result.maximum && result.minimum === "1970-01-01 00:00:00") {
    result.minimum = result.maximum = moment().format("YYYY-MM-DD 00:00:00")
  }

  localStorage.setItem(config.DATE_LIMIT_KEY, JSON.stringify(result));

  return result
};

export const updateDateRange = () => {
  const search = new URLSearchParams(window.location.search)

  if (!search.get('since') && !search.get('till')) {
    return
  }

  const inquery = {
    since: parseInt(search.get('since')) || 0,
    till: parseInt(search.get('till')) || 0
  }

  const cached = JSON.parse(localStorage.getItem(config.DATE_DATA_KEY)) || {
    since: 0,
    till: 0
  };

  if (cached.since !== inquery.since || cached.till !== inquery.till) {
    localStorage.setItem(config.DATE_DATA_KEY, JSON.stringify(inquery))
  }
}

export const dateRange = (flushed = false) => {
  const search = new URLSearchParams(window.location.search)

  let params = {
    since: parseInt(search.get('since')) || 0,
    till: parseInt(search.get('till')) || 0
  }

  if (flushed) {
    params.since = 0
    params.till = 0
  }

  if (!params.since) {
    params = JSON.parse(localStorage.getItem(config.DATE_DATA_KEY)) || {};
  }

  if (!params.since) {
    params.since = moment().subtract(15, 'days').subtract(1, "months").startOf("month").unix()
    params.till = moment().subtract(15, 'days').subtract(1, "months").endOf("month").unix()
  }

  return params
}

export const defHomeRedir = (flushed = false) => {
  const params = dateRange(flushed)
  return `/home?since=${params.since}&till=${params.till}`
}

export const arePropsChanged = (oldProps, newProps) => {
  let isChanged = false

  Object.keys(oldProps).forEach((key) => {

    if (typeof oldProps[key] !== "function" && oldProps[key] !== newProps[key]) {
      isChanged = true
    }
  })

  Object.keys(newProps).forEach((key) => {
    if (typeof newProps[key] !== "function" && oldProps[key] !== newProps[key]) {
      isChanged = true
    }
  })

  return isChanged
}

export const apiPageFilters = () => {
  const parts = new URL(window.location.href).pathname.split("/").filter(x => !!x)
  const pages = ["channels", "brands", "products", "categories"]
  const pageType = parts[0].toLocaleLowerCase()

  let filter = {}
  if (pages.includes(pageType) && parts.length > 1 && parts[1].trim().length > 0) {
    filter.key = pageType
    filter.value = decodeURIComponent(parts[1]).trim()
  }

  return filter
}