import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Modal, ModalBody } from "reactstrap";
// library

import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchDateLimit } from "./utils";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker as ReactDateRangePicker } from 'react-date-range';
import * as utils from "../common/utils";
import { config } from "../../config/contants";

const CustomDateRangePicker = (props) => {

  const [isLoading, setLoading] = useState(() => {
    return !utils.hasValidDateLimit()
  })

  const [dateLimit, _setDateLimit] = useState(() => {
    if (utils.hasValidDateLimit()) {
      const limit = JSON.parse(localStorage.getItem(config.DATE_LIMIT_KEY))

      return {
        minimum: moment(limit.minimum),
        maximum: moment(limit.maximum)
      }
    }

    return {
      minimum: moment(),
      maximum: moment()
    }
  })

  const setDateLimit = (data) => {
    _setDateLimit({
      minimum: moment(data.minimum),
      maximum: moment(data.maximum)
    })
    setLoading(false)
  }

  const [selectedRange, setSelectedRange] = useState({
    since: moment(),
    till: moment()
  })

  const [calendarRange, setCalenderRange] = useState({
    since: moment(),
    till: moment()
  })
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (isLoading) {
      fetchDateLimit().then((data) => {
        setDateLimit(data)
      });
    }
  }, [isLoading])

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (isLoading) {
      return
    }

    const params = utils.dateRange()

    let range = {
      since: moment(params.since * 1000),
      till: moment(params.till * 1000)
    }

    if (range.since.isAfter(range.till)) {
      range.since = range.till.subtract(15, 'days')
    }

    if (range.since.isBefore(dateLimit.minimum)) {
      range.since = dateLimit.minimum
    }

    if (range.till.isAfter(dateLimit.maximum)) {
      range.till = dateLimit.maximum
    }

    setSelectedRange(range)
    setCalenderRange(range)

  }, [dateLimit, isLoading])

  if (isLoading) {
    return <></>
  }


  console.log(`min: ${dateLimit.minimum.toDate()} max: ${dateLimit.maximum.toDate()}`)

  return (
    <div>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        size="lg"
        style={{ width: '890px', maxWidth: '890px' }}
      >
        <div className="card-header">
          <h6 className="float-left">Select Date Range</h6>
          <button
            type="button"
            className="close float-right"
            data-dismiss="modal"
            onClick={() => setShowModal(false)}
          >
            <i className="lni lni-close"></i>
          </button>
        </div>

        <ModalBody className="card p-0 rounded-0">
          <div className="row">
            <div className="col">
              <ReactDateRangePicker
                ranges={[
                  {
                    startDate: calendarRange.since.toDate(),
                    endDate: calendarRange.till.toDate(),
                    key: 'selection'
                  }
                ]}
                inputRanges={[]}
                minDate={dateLimit.minimum.toDate()}
                maxDate={dateLimit.maximum.toDate()}
                months={2}
                direction={"horizontal"}
                onChange={
                  (rangesByKey) => {
                    let range = {
                      since: moment(rangesByKey['selection'].startDate),
                      till: moment(rangesByKey['selection'].endDate)
                    }

                    if (range.since.isBefore(dateLimit.minimum) || range.till.isAfter(dateLimit.maximum)) {
                      setErrorMessage('Premium account is Required. Please Contact Support')
                      return
                    }
                    setErrorMessage("")
                    setCalenderRange(range)
                  }}
              />
              {errorMessage && (
                <p className="text-center text-danger">
                  {errorMessage}
                </p>
              )}
            </div>
          </div>
        </ModalBody>
        <div className="modal-footer">
          <button className="btn btn-link" onClick={() => {
            setCalenderRange(selectedRange)
          }}>
            Reset
          </button>
          <button className="btn btn-primary" onClick={
            () => {
              setShowModal(false)
              setSelectedRange(calendarRange)

              const dateRange = {
                since: calendarRange.since.startOf('day').unix(),
                till: calendarRange.till.endOf('day').unix()
              }

              localStorage.setItem(config.DATE_DATA_KEY, JSON.stringify(dateRange));

              props.onDateChange(dateRange)
            }
          }>
            Submit
          </button>
        </div>
      </Modal >
      <div className="row">
        <div className="col-6">

        </div>
        <div className="col-6 text-right">
          <button
            type="button"
            className="btn btn-sm text-truncate btn-outline-secondary rounded-pill"
            onClick={() => {
              setCalenderRange(selectedRange)
              setShowModal(true)
            }}
          >
            <FontAwesomeIcon icon={"calendar-alt"} />{" "}
            {isLoading ? "" :
              `${selectedRange.since.format("DD-MMM-YYYY")} to ${selectedRange.till.format("DD-MMM-YYYY")}`
            }
          </button>
        </div>
      </div>
    </div >
  );
}

export default CustomDateRangePicker