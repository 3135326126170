import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, CardText } from "reactstrap";
import * as utils from "../common/utils";
import { ApiList } from "../../config/apiList";
import API from "../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

const headers = ["playout", "airtime", "spend"]

const Similar = (props) => {

    const containerRef = useRef()
    const location = useLocation()

    const [pivotsDetails, setPivotDetails] = useState([])
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        getPivots()
    }, [props.pivot, props.since, props.till, props.sortBy, location])

    const getPivots = async () => {
        setLoading(true)

        const filter = utils.apiPageFilters()

        const URL = `${ApiList.env}${ApiList.endpoint.similar}${props.pivot
            }/${encodeURIComponent(filter.value)}/${props.sortBy}?limit=${props.limit
            }`;

        const data = await API.call(URL, true)

        loadPivotDetails(data)
    }

    const loadPivotDetails = (data) => {

        const details = data.map(x => {
            return {
                title: x,
                isLoading: true
            }
        })

        setPivotDetails(details)

        const promises = data.map(async (i, index) => {
            const URL = `${ApiList.env}${ApiList.endpoint.summary}?since=${props.since
                }&till=${props.till}&${encodeURIComponent(
                    props.pivot
                )}=${encodeURIComponent(i)}`;

            return await API.call(URL, true)
        });

        Promise.all(promises).then((items) => {

            items.map((item, idx) => {
                details[idx].data = item
                details[idx].isLoading = false
            })

            setPivotDetails(details)
            setLoading(false)
        })
    }

    const sortedPivot = () => {
        if (pivotsDetails.filter(x => x.isLoading).length > 0) {
            return pivotsDetails
        }

        return pivotsDetails.sort((a, b) =>
            a.data.curr[props.sortBy] >
                b.data.curr[props.sortBy]
                ? -1
                : b.data.curr[props.sortBy] >
                    a.data.curr[props.sortBy]
                    ? 1
                    : 0
        )
    }

    const navigate = (scrollOffset) => {
        containerRef.current.scrollLeft += scrollOffset;
        return false
    }

    return (
        <div className="row mt-5">
            <div className="col">
                <h5 className="mb-3">
                    <span className="page-icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="12"
                            viewBox="0 0 25 12"
                        >
                            <path
                                fill="#FFF"
                                fillRule="nonzero"
                                d="M22.302.033c-1.46 0-2.65 1.2-2.65 2.674 0 .538.16 1.038.431 1.458l-2.862 2.889a2.616 2.616 0 0 0-2.935.03l-2.892-2.92c.272-.419.43-.92.43-1.457 0-1.474-1.188-2.674-2.649-2.674-1.46 0-2.65 1.2-2.65 2.674 0 .538.16 1.038.431 1.458L4.094 7.053a2.615 2.615 0 0 0-1.445-.434C1.19 6.62 0 7.82 0 9.293s1.188 2.674 2.65 2.674c1.46 0 2.649-1.2 2.649-2.674 0-.538-.16-1.038-.43-1.458L7.73 4.946a2.616 2.616 0 0 0 2.89 0l2.908 2.935c-.254.41-.401.894-.401 1.412 0 1.474 1.188 2.674 2.65 2.674 1.46 0 2.648-1.2 2.648-2.674 0-.538-.158-1.038-.43-1.458l2.863-2.888c.415.274.911.434 1.444.434 1.46 0 2.65-1.2 2.65-2.674S23.761.033 22.301.033zM2.649 10.861a1.563 1.563 0 0 1-1.554-1.568c0-.865.697-1.569 1.554-1.569.857 0 1.555.704 1.555 1.569s-.698 1.568-1.555 1.568zm6.526-6.585a1.563 1.563 0 0 1-1.554-1.569c0-.865.697-1.568 1.554-1.568.857 0 1.554.703 1.554 1.568 0 .865-.697 1.569-1.554 1.569zm6.601 6.585a1.563 1.563 0 0 1-1.554-1.568c0-.865.697-1.569 1.554-1.569.857 0 1.554.704 1.554 1.569s-.697 1.568-1.554 1.568zm6.526-6.585a1.563 1.563 0 0 1-1.554-1.569c0-.865.697-1.568 1.554-1.568.857 0 1.554.703 1.554 1.568 0 .865-.697 1.569-1.554 1.569z"
                            />
                        </svg>
                    </span>{" "}
                    Similar{" "}
                    {props.pivot.replace("brands", "advertisers")}
                    <p className="float-right d-none">
                        <a
                            href={"/compare?section=" + props.pivot}
                            className="text-primary"
                        >
                            Compare {props.pivot}
                        </a>
                    </p>
                </h5>
                <div className="scroll-wrapper">
                    <a href="#" className="navigation left">
                        <FontAwesomeIcon
                            icon="chevron-left"
                            onClick={() => {
                                navigate(-200);
                            }}
                        />
                    </a>
                    <a className="navigation right">
                        <FontAwesomeIcon
                            icon="chevron-right"
                            onClick={() => {
                                navigate(200);
                            }}
                        />
                    </a>
                    <div
                        className="scrollable-container"
                        ref={containerRef}
                    >
                        {pivotsDetails.length ||
                            !isLoading ? (
                            sortedPivot().map((j, index) => {
                                return (
                                    <Card key={index}
                                        onClick={() => {
                                            window.location.href = `/${props.pivot}/${encodeURIComponent(j.title)
                                                }?${utils.getParams(props)}`
                                        }}
                                        className="cursor-pointer">
                                        <CardHeader>
                                            <CardTitle className="mb-0">
                                                <CardText className="text-primary">
                                                    {j.title}
                                                </CardText>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            {j.isLoading ?
                                                <div className="loading" /> :
                                                (<div className="row mt-4">
                                                    {headers.map(
                                                        (k, l) => {
                                                            return (
                                                                <div
                                                                    className="col"
                                                                    key={
                                                                        j.title +
                                                                        l
                                                                    }
                                                                >
                                                                    <h6 className="text-muted">
                                                                        {k}
                                                                    </h6>
                                                                    <div>
                                                                        {utils.formatter(
                                                                            k,
                                                                            j
                                                                                .data
                                                                                .curr[
                                                                            k
                                                                            ]
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>

                                                )}
                                        </CardBody>
                                    </Card>
                                );
                            })
                        ) : (
                            <Card>
                                <CardBody>
                                    {!isLoading ? 'No data found' :
                                        <div className="loading" />}
                                </CardBody>
                            </Card>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Similar;
