import React, { Component } from "react";

// Library
import { ApiList } from "../../config/apiList";
import toast from 'react-hot-toast'

class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      oldpassword: "",
      newpassword: "",
    };
    this.handleChangeOldpassword = this.handleChangeOldpassword.bind(this);
    this.handleChangeNewpassword = this.handleChangeNewpassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.body.classList.add("dark");
  }

  handleChangeOldpassword(event) {
    this.setState({ oldpassword: event.target.value });
  }

  handleChangeNewpassword(event) {
    this.setState({ newpassword: event.target.value });
  }

  showError = (text) => {
    toast.error(text, {
      position: 'top-right'
    });
  };

  handleSubmit(event) {
    event.preventDefault();

    if (
      this.state.oldpassword === "" ||
      this.state.oldpassword === null ||
      this.state.oldpassword === 0
    ) {
      this.showError("Please enter old password");
      return;
    }

    if (
      this.state.newpassword === "" ||
      this.state.newpassword === null ||
      this.state.newpassword === 0
    ) {
      this.showError("Please enter new password");
      return;
    }

    this.setAuth(this.state.oldpassword, this.state.newpassword);
  }

  setAuth = async (current, update) => {
    const dataString = {
      current: current,
      updated: update,
    };

    const response = await fetch(ApiList.env + ApiList.endpoint.changepassword, {
      method: 'POST',
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataString),
    })

    if (response.status > 299) {
      this.showError("Old password entered is incorrect");
      return
    }

    await fetch(ApiList.env + ApiList.endpoint.logout, {
      credentials: 'include',
    })

    localStorage.removeItem("cards");
    localStorage.removeItem("account_details");
    window.location = "/login";
  };

  render() {
    return (
      <div className="sign-in-component">
        <div className="container-fluid h-100">
          <div className="row sign-in-row">
            <div className="p-3 py-4 mb-2  text-center ">
              <a href="/home" className="backbtn">
                <svg
                  className="bi bi-arrow-left-short"
                  width="1em"
                  height="1em"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9.854 6.646a.5.5 0 010 .708L7.207 10l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M6.5 10a.5.5 0 01.5-.5h6.5a.5.5 0 010 1H7a.5.5 0 01-.5-.5z"
                    clip-rule="evenodd"
                  />
                </svg>{" "}
                Back To Dashboard
              </a>
            </div>
            <div className="col-md-12 sign-in-right-col">
              <div className="row justify-content-center h-100 align-items-center">
                <div className="col-xl-4 login-section">
                  <h2>Change Password</h2>
                  <form onSubmit={this.handleSubmit}>
                    <input
                      type="password"
                      placeholder="Old Password"
                      className="form-control oldpassword-input mt-5"
                      aria-label="oldpassword input"
                      aria-describedby=""
                      value={this.state.oldpassword}
                      onChange={this.handleChangeOldpassword}
                    />
                    <input
                      type="password"
                      placeholder="New Password"
                      className="form-control newpassword-input mt-5"
                      aria-label="newpassword input"
                      aria-describedby=""
                      value={this.state.newpassword}
                      onChange={this.handleChangeNewpassword}
                    />
                    <button className="btn bg-white mt-5" type="submit">
                      Change Passowrd
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ChangePassword;
