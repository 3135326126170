import React, { Component } from "react";
// libraries
import moment from "moment";
import { ApiList } from "../../config/apiList";

import * as utils from "../common/utils";

// custom
import { Line } from "react-chartjs-2";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import Lookup from "../../config/lookup";

import API from "../../config/api";

class BottomSortCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayLabel: "",
            labels: [],
            data: [],
            heatChartData: [],
            amPmFlag: false,
            chartKeys: [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
            ],
            isMultiFlag: false,
            datasets: [],
            bgColor: [
                "rgb(91, 202, 221)",
                "rgb(166, 224, 206)",
                "rgb(255, 152, 120)",
                "rgb(58, 180, 73)",
                "rgb(234, 86, 124)",
            ],
            preGradient: [
                "rgba(91, 202, 221,0.2)",
                "rgba(166, 224, 206,0.2)",
                "rgba(255, 152, 120,0.2)",
                "rgba(58, 180, 73,0.2)",
                "rgba(234, 86, 124,0.2)",
            ],
            limitExccedFlag: false,
        };
    }

    componentDidMount = () => {
        this.setState(
            {
                datasets: [],
            },
            () => {
                this.getDataListCall();
            }
        );
    };

    replaceUrlParam(url, paramName, paramValue) {
        let href = new URL(url);
        href.searchParams.set(paramName, paramValue);
        return href.toString();
    }

    getDataListCall = () => {
        if (this.props.multi === "brands") {

            if (this.props.productsFilter.length === 0) {
                return
            }

            let URL = `${ApiList.env}${ApiList.endpoint.statistics}daily?since=${this.props.since}&till=${this.props.till}&sortBy=${this.props.sortBy}`;

            this.setState({
                limitExccedFlag:
                    this.props.productsFilter.length > 5
                        ? true
                        : false,
            });

            this.props.productsFilter.forEach((productName, i) => {
                if (i < 5) {
                    this.getLineDataMulti("products", productName, URL);
                }
            });
        } else {
            this.getData();
        }

        this.getStatisticsHourly();
    };

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState(
                {
                    datasets: [],
                },
                () => {
                    this.getDataListCall();
                }
            );
        }
    }
    getData = () => {
        const URL = `${ApiList.env}${ApiList.endpoint.statistics}daily?since=${this.props.since}&till=${this.props.till}&sortBy=${this.props.sortBy}`;

        API.call(URL).then((data) => {
            let xData = [];
            let yData = [];

            data.forEach((item, key) => {
                if (key < 7) {
                    xData.push(item.count ? item.count : 0);
                    yData.push(
                        item.date ? moment(item.date).format("DD/MM") : 0
                    );
                }
            });
            let temp = [
                {
                    label: ":",
                    data: xData,
                },
            ];
            this.setState({
                datasets: temp,
                labels: yData,
            });
        });
    };
    getLineDataMulti = (queryName, queryValue, URL) => {
        URL += `&${queryName}=${encodeURIComponent(queryValue)}`;
        API.call(URL).then((data) => {
            let xData = [];
            let yData = [];
            data.forEach((item, key) => {
                if (key < 7) {
                    xData.push(item.count ? item.count : 0);
                    yData.push(item.date ? moment(item.date).format("DD") : 0);
                }
            });

            let temp = {
                label: queryValue,
                data: xData,
            };

            let tempArr = this.state.datasets;
            tempArr.push(temp);
            this.setState({
                datasets: tempArr,
                labels: yData,
                displayLabel: queryName,
            });
        });
    };

    getStatisticsHourly = () => {
        const URL = `${ApiList.env}${ApiList.endpoint.statistics}dailyhourly?since=${this.props.since}&till=${this.props.till}&sortBy=${this.props.sortBy}`;

        API.call(URL).then((data) => {
            var monthDays = {};

            data.forEach((item) => {
                const m = moment(item.dateHour);
                var monthDay = m.format("MMM DD");
                var hour = m.hour();

                var monthDayArr = monthDays[monthDay];

                if (!monthDayArr) {
                    monthDayArr = Array(24).fill(0);
                }

                monthDayArr[hour] = item.count;
                monthDays[monthDay] = monthDayArr;
            });

            const keys = Object.keys(monthDays);
            var result = [];
            let nth = parseInt(result.length / 10) || 1;
            keys.forEach((key) => {
                const array = monthDays[key];
                var obj = {};
                obj[key] = array.map(function (N, I) {
                    return { hour: I, count: N };
                });
                result.push(obj);
            });

            var convertToHeatData = [];
            result
                .filter((e, i) => i % nth === nth - 1)
                .forEach((itemValue) => {
                    var date = Object.keys(itemValue);
                    var tempHold = {};
                    itemValue[date].forEach((itemhour) => {
                        tempHold = {
                            ...tempHold,
                            [itemhour.hour]: itemhour.count,
                        };
                    });
                    tempHold = { ...tempHold, date: date.toString() };
                    convertToHeatData.push(tempHold);
                });
            this.setState({
                heatChartData: convertToHeatData,
            });
        });
    };

    amPmHandler = (event) => {
        let selected = event.target.value;
        if (selected === "am/pm") {
            this.setState({
                chartKeys: [
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                ],
            });
        } else if (selected === "am") {
            this.setState({
                chartKeys: [
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                ],
            });
        } else if (selected === "pm") {
            this.setState({
                chartKeys: [
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                ],
            });
        }
    };
    render() {
        const { isMultiFlag, preGradient, limitExccedFlag } = this.state;
        const dataLine = ((canvas) => {
            //const ctx = canvas.getContext('2d');  **
            // const gradient = ctx.createLinearGradient(0, 0, 0, 450);
            // gradient.addColorStop(0, 'rgba(239,160,124, 0.2)');
            // gradient.addColorStop(1, 'rgba(0,0,0,0)');

            let dataColl = [];
            this.state.datasets.forEach((item, i) => {
                let dataset = {
                    label: item.label,
                    data: item.data,
                    backgroundColor: preGradient[i],
                    borderColor: this.state.bgColor[i],
                };
                dataColl.push(dataset);
            });
            return {
                labels: this.state.labels,
                datasets: dataColl,
                borderColor: "#86367e",
                pointBackgroundColor: "#ffffff",
                pointBorderColor: "#86367e",
                pointHoverBackgroundColor: "#86367e",
                pointHoverBorderColor: "rgba(134, 54, 126,0.5)",
                pointRadius: 4,
                pointBorderWidth: 2,
                pointHoverRadius: 5,
                fill: true,
                background: "rgba(134, 54, 126,0.5)",
            };
        })();
        var lineOption = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                mode: "index",
                intersect: false,
                callbacks: {
                    label: (tooltipItems, data) => {
                        return utils.formatter(
                            this.props.sortBy,
                            tooltipItems.yLabel
                        );
                    },
                },
            },
            animation: {
                easing: "easeInOutQuad",
                duration: 520,
            },
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            color: "#464967",
                            display: false,
                            lineWidth: 1,
                            zeroLineColor: "#464967",
                        },
                        ticks: { padding: 10 },
                    },
                ],
                yAxes: [
                    {
                        gridLines: {
                            color: "#464967",
                            display: false,
                            lineWidth: 1,
                            zeroLineColor: "#464967",
                        },
                        ticks: {
                            beginAtZero: true,
                            padding: 3,
                            callback: (value, index, values) => {
                                return utils.formatter(
                                    this.props.sortBy,
                                    value
                                );
                            },
                        },
                    },
                ],
            },
            elements: {
                line: {
                    tension: isMultiFlag === false ? 0 : 0.4,
                },
                point: { radius: 4 },
            },
            legend: { display: false },
        };
        const theme = {
            axis: {
                fontSize: "14px",
                tickColor: "#eee",
                ticks: {
                    line: {
                        stroke: "#555555",
                    },
                    text: {
                        fill: "#aaaaaa",
                    },
                },
                legend: {
                    text: {
                        fill: "#aaaaaa",
                    },
                },
            },
            grid: {
                line: {
                    stroke: "#555555",
                },
            },
            tooltip: {
                container: {
                    background: "#000000",
                    color: "#FFF",
                },
            },
        };
        return (
            <div className="row mt-3">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header">
                            <header>{this.props.sortBy}</header>
                        </div>
                        <div className="w-100 card-body">
                            {limitExccedFlag ? (
                                <div className="notify-message">
                                    You can view insights for a maximum of 5{" "}
                                    {Lookup.display[this.state.displayLabel] ||
                                        this.state.displayLabel}{" "}
                                    at once
                                </div>
                            ) : null}
                            <ul
                                className="nav nav-tabs"
                                id="mTab"
                                role="tablist"
                            >
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setState({ amPmFlag: false });
                                    }}
                                >
                                    <a
                                        className="nav-link active"
                                        id="daily-tab"
                                        data-toggle="tab"
                                        href="#daily"
                                        role="tab"
                                        aria-controls="daily"
                                        aria-selected="true"
                                    >
                                        daily
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setState({ amPmFlag: true });
                                    }}
                                >
                                    <a
                                        className="nav-link"
                                        id="hourly-tab"
                                        data-toggle="tab"
                                        href="#hourly"
                                        role="tab"
                                        aria-controls="hourly"
                                        aria-selected="false"
                                    >
                                        hourly
                                    </a>
                                </li>

                                <li
                                    className="nav-item ml-auto"
                                    style={{
                                        display: this.state.amPmFlag
                                            ? "block"
                                            : "none",
                                    }}
                                >
                                    <div className="custom-select-container mr-5">
                                        <select
                                            className="custom-select custom-select-input"
                                            onChange={this.amPmHandler}
                                        >
                                            <option value="am/pm">AM/PM</option>
                                            <option value="am">AM</option>
                                            <option value="pm">PM</option>
                                        </select>
                                    </div>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="daily"
                                    role="tabpanel"
                                    aria-labelledby="daily-tab"
                                >
                                    <div className="card">
                                        <div
                                            className="card-body"
                                            style={{ height: "400px" }}
                                        >
                                            {this.state.labels.length ? (
                                                <Line
                                                    data={dataLine}
                                                    options={lineOption}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        {this.state.datasets.length > 1 ? (
                                            <div className="card-footer">
                                                <footer className="row">
                                                    {this.state.datasets
                                                        .slice(0, 5)
                                                        .map(
                                                            (
                                                                datasetsItems,
                                                                datasetsKeys
                                                            ) => {
                                                                return (
                                                                    <small
                                                                        className="col-3"
                                                                        key={
                                                                            datasetsItems.label +
                                                                            Math.random(
                                                                                0,
                                                                                10
                                                                            )
                                                                        }
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                color: this
                                                                                    .state
                                                                                    .bgColor[
                                                                                    datasetsKeys
                                                                                ],
                                                                            }}
                                                                        >
                                                                            &#9673;
                                                                        </span>{" "}
                                                                        {
                                                                            datasetsItems.label
                                                                        }
                                                                    </small>
                                                                );
                                                            }
                                                        )}
                                                </footer>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="hourly"
                                    role="tabpanel"
                                    aria-labelledby="hourly-tab"
                                >
                                    <div className="card">
                                        <div
                                            className="card-body"
                                            style={{ height: "400px" }}
                                        >
                                            <ResponsiveHeatMap
                                                data={this.state.heatChartData}
                                                keys={this.state.chartKeys}
                                                indexBy="date"
                                                margin={{
                                                    top: 0,
                                                    right: 0,
                                                    bottom: 50,
                                                    left: 50,
                                                }}
                                                colors="reds"
                                                borderColor={{
                                                    from: "color",
                                                    modifiers: [
                                                        ["darker", 0.6],
                                                        ["opacity", 0.5],
                                                    ],
                                                }}
                                                axisTop={null}
                                                axisRight={null}
                                                axisBottom={{
                                                    orient: "bottom",
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legendPosition: "middle",
                                                    legendOffset: 36,
                                                }}
                                                axisLeft={{
                                                    orient: "left",
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: "",
                                                    legendPosition: "middle",
                                                    legendOffset: -40,
                                                }}
                                                cellOpacity={1}
                                                enableLabels={false}
                                                labelTextColor={{
                                                    from: "color",
                                                    modifiers: [
                                                        ["darker", 1.8],
                                                    ],
                                                }}
                                                defs={[
                                                    {
                                                        id: "lines",
                                                        type: "patternLines",
                                                        background: "inherit",
                                                        color: "rgba(0, 0, 0, 0.1)",
                                                        rotation: -45,
                                                        lineWidth: 4,
                                                        spacing: 7,
                                                    },
                                                ]}
                                                fill={[{ id: "lines" }]}
                                                animate={true}
                                                motionStiffness={80}
                                                theme={theme}
                                                motionDamping={9}
                                                hoverTarget="cell"
                                                cellHoverOthersOpacity={0.25}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BottomSortCard;
