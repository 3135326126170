import React, { Component } from "react";

// libraries
import { ApiList } from "../../config/apiList";

// components
import TopCard from "../common/topCard";
import API from "../../config/api";

class topSortCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topCardData: {
                curr: {
                    playout: 0,
                    airtime: 0,
                    spend: 0,
                },
                prev: {
                    playout: 0,
                    airtime: 0,
                    spend: 0,
                },
            },
            flag: false,
        };
    }

    componentDidMount() {
        this.topThreeCard();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.topThreeCard();
        }
    }

    topThreeCard = () => {
        const URL = `${ApiList.env}${ApiList.endpoint.summary}?since=${this.props.since}&till=${this.props.till}`;

        API.call(URL).then((data) => {
            // console.log("topcard data", data);
            this.setState({
                topCardData: data,
                flag: true,
            });
        });
    };

    render() {
        return (
            <div>
                {this.state.flag ? (
                    <div className="row mt-4">
                        <div className="col-md-4">
                            <TopCard
                                name={"playout"}
                                sortBy={"playout"}
                                count={this.state.topCardData.curr.playout}
                                since={this.props.since}
                                till={this.props.till}
                                prevCount={this.state.topCardData.prev.playout}
                                color={"239,160,124"}
                            />
                        </div>
                        <div className="col-md-4">
                            <TopCard
                                name={"airtime"}
                                sortBy={"airtime"}
                                count={this.state.topCardData.curr.airtime}
                                since={this.props.since}
                                till={this.props.till}
                                prevCount={this.state.topCardData.prev.airtime}
                                color={"102,188,214"}
                            />
                        </div>
                        <div className="col-md-4">
                            <TopCard
                                name={"spend"}
                                sortBy={"spend"}
                                count={this.state.topCardData.curr.spend}
                                since={this.props.since}
                                till={this.props.till}
                                prevCount={this.state.topCardData.prev.spend}
                                color={"147,115,243"}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default topSortCards;
