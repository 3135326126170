import React, { Component, Suspense } from "react";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import Lookup from "../../config/lookup";

//Components
import OverviewTab from "./overviewTab";

import Topcards from "./topCards";
import DateRangePicker from "../common/dateRangePicker";
import SortByFilter from "../common/sortby";
import * as utils from "../common/utils";

//css

class Dashboard extends Component {
    constructor() {
        super();
        const dateRange = utils.dateRange()
        this.state = {
            sortBy: "playout",
            tabList: ["channels", "categories", "brands", "products"],
            activeTab: ["channels"],
            ...dateRange
        };
    }

    componentDidMount() {
        this.setState({
            activeTab: this.state.tabList[0],
        });
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
    };

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="container-fluid">
                        <div className="row justify-content-end align-items-center">
                            <div className="col-xl-2">
                                <SortByFilter
                                    options={["India", "Indonesia"]}
                                    selected={utils.getCountry()}
                                    disableLoadOnInit={true}
                                    onClickCallback={(option) => {
                                        if (utils.getCountry() === option) {
                                            return
                                        }
                                        utils.setCountry(option)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row justify-content-between align-items-center mb-5 py-3 border-bottom">
                            <div className="col-xl-5">
                                <h4>Dashboard</h4>
                            </div>

                            <div className="col-xl-7">
                                <DateRangePicker
                                    onDateChange={(data) => {
                                        this.setState(data);
                                    }}
                                />
                            </div>
                        </div>

                        {/* top cards  */}
                        <Topcards
                            since={this.state.since}
                            till={this.state.till}
                        />

                        {/* overview section */}
                        <div className="row mt-5">
                            <div className="container-fluid">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-xl-4">
                                        <h5>
                                            <span className="page-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="25"
                                                    height="12"
                                                    viewBox="0 0 25 12"
                                                >
                                                    <path
                                                        fill="#FFF"
                                                        fillRule="nonzero"
                                                        d="M22.302.033c-1.46 0-2.65 1.2-2.65 2.674 0 .538.16 1.038.431 1.458l-2.862 2.889a2.616 2.616 0 0 0-2.935.03l-2.892-2.92c.272-.419.43-.92.43-1.457 0-1.474-1.188-2.674-2.649-2.674-1.46 0-2.65 1.2-2.65 2.674 0 .538.16 1.038.431 1.458L4.094 7.053a2.615 2.615 0 0 0-1.445-.434C1.19 6.62 0 7.82 0 9.293s1.188 2.674 2.65 2.674c1.46 0 2.649-1.2 2.649-2.674 0-.538-.16-1.038-.43-1.458L7.73 4.946a2.616 2.616 0 0 0 2.89 0l2.908 2.935c-.254.41-.401.894-.401 1.412 0 1.474 1.188 2.674 2.65 2.674 1.46 0 2.648-1.2 2.648-2.674 0-.538-.158-1.038-.43-1.458l2.863-2.888c.415.274.911.434 1.444.434 1.46 0 2.65-1.2 2.65-2.674S23.761.033 22.301.033zM2.649 10.861a1.563 1.563 0 0 1-1.554-1.568c0-.865.697-1.569 1.554-1.569.857 0 1.555.704 1.555 1.569s-.698 1.568-1.555 1.568zm6.526-6.585a1.563 1.563 0 0 1-1.554-1.569c0-.865.697-1.568 1.554-1.568.857 0 1.554.703 1.554 1.568 0 .865-.697 1.569-1.554 1.569zm6.601 6.585a1.563 1.563 0 0 1-1.554-1.568c0-.865.697-1.569 1.554-1.569.857 0 1.554.704 1.554 1.569s-.697 1.568-1.554 1.568zm6.526-6.585a1.563 1.563 0 0 1-1.554-1.569c0-.865.697-1.568 1.554-1.568.857 0 1.554.703 1.554 1.568 0 .865-.697 1.569-1.554 1.569z"
                                                    />
                                                </svg>
                                            </span>{" "}
                                            Overview
                                        </h5>
                                    </div>
                                    <div className="col-xl-2">
                                        <Suspense
                                            fallback={<div>Loading...</div>}
                                        >
                                            <SortByFilter
                                                label="Preference"
                                                onClickCallback={(option) => {
                                                    this.setState({
                                                        sortBy: option,
                                                    });
                                                }}
                                            />
                                        </Suspense>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <Nav tabs>
                                            {this.state.tabList.map((tab) => {
                                                return (
                                                    <NavItem key={tab}>
                                                        <NavLink
                                                            className={classnames(
                                                                {
                                                                    active:
                                                                        this
                                                                            .state
                                                                            .activeTab ===
                                                                        tab,
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                this.toggle(
                                                                    tab
                                                                );
                                                            }}
                                                        >
                                                            {Lookup.display[
                                                                tab
                                                            ] || tab}
                                                        </NavLink>
                                                    </NavItem>
                                                );
                                            })}
                                        </Nav>
                                    </div>
                                    <div className="col-xl-12">
                                        <TabContent
                                            activeTab={this.state.activeTab}
                                        >
                                            {this.state.tabList.map((tab) => {
                                                return (
                                                    <TabPane
                                                        tabId={tab}
                                                        key={tab}
                                                    >
                                                        {
                                                            this.state.activeTab ===
                                                                tab ? (
                                                                <Suspense
                                                                    fallback={
                                                                        <div>
                                                                            Loading...
                                                                        </div>
                                                                    }
                                                                >
                                                                    <OverviewTab
                                                                        since={
                                                                            this
                                                                                .state
                                                                                .since
                                                                        }
                                                                        till={
                                                                            this
                                                                                .state
                                                                                .till
                                                                        }
                                                                        sortBy={
                                                                            this
                                                                                .state
                                                                                .sortBy
                                                                        }
                                                                        forName={
                                                                            tab
                                                                        }
                                                                    />
                                                                </Suspense>
                                                            ) : null}
                                                    </TabPane>
                                                );
                                            })}
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;
