import React, { Component } from "react";

// libraries
import { ApiList } from "../../config/apiList";

import API from "../../config/api";
import moment from "moment";
import * as utils from "../common/utils"
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: "playout",
      searching: false,
      productsData: [],
      advertisersData: [],

      displayProducts: [],

      isActive: false,
      query: "",
      filter: undefined
    };
    this.timer = 0;
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isActive: false, query: "" }, () => {
        this.fetchSearch();
      });
    } else {
      this.setState({ isActive: true });
    }
  }

  fetchSearch = (event) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    if (this.state.query.length >= 3) {
      if (!this.state.searching) {
        this.setState({ searching: true });
      }

      const URL = `${ApiList.env}${ApiList.endpoint.search
        }?query=${encodeURIComponent(this.state.query)}`;

      this.timer = setTimeout(() => {
        API.call(URL, true).then((data) => {

          this.setState({
            productsData: data.products.filter(x => !!x.product.trim()),
            advertisersData: new Array(...new Set(data.advertisers.map(x => x.advertiser.trim()))).filter(x => !!x).sort(),
            searching: false,
          });
        });
      }, 300);
    } else {
      this.setState({
        productsData: [],
        advertisersData: [],
        searching: false
      });
    }
  };

  product_start_date = (item) => {
    if (item.count > 0) {
      return moment(item.start_date).unix();
    }

    const dateRange = utils.dateRange()

    return dateRange.since;
  };

  product_end_date = (item) => {
    if (item.count > 0) {
      return moment(item.end_date).unix();
    }

    const dateRange = utils.dateRange()

    return dateRange.till;
  };

  advertiser_date_range = () => {
    const dateRange = utils.dateRange()
    return `since=${dateRange.since}&till=${dateRange.till}`
  }

  filter(cutOff) {
    if (this.state.filter === cutOff) {
      this.setState({
        filter: undefined
      })
    } else {
      this.setState({
        filter: cutOff
      })
    }
  }

  sortFun(a, b) {
    return moment(b.start_date).diff(moment(a.start_date))
  }


  render() {
    const { productsData, advertisersData } = this.state;
    let displayProducts = [...productsData]

    if (this.state.filter) {
      const cutOff = moment().subtract(this.state.filter, 'days')

      displayProducts = displayProducts.filter(x => moment(x.start_date).isAfter(cutOff))
    }

    displayProducts = displayProducts.sort(this.sortFun)

    return (
      <div
        className={
          this.state.isActive ? "search-wrapper active" : "search-wrapper"
        }
        ref={this.setWrapperRef}
      >
        <div className="search-bar">
          <input
            placeholder="Search"
            type="search"
            className="form-control"
            onKeyUp={(e) => {
              if (!this.state.searching) {
                this.setState({
                  searching: false,
                  productsData: [],
                });
              }
              if (e.key === "Escape") {
                this.setState(
                  {
                    isActive: false,
                    query: "",
                  },
                  () => {
                    this.fetchSearch();
                  }
                );
              }
              if (e.key === "Enter") {
                this.fetchSearch();
              }
            }}
            onChange={(e) => {
              this.setState(
                {
                  query: e.target.value,
                },
                () => {
                  this.fetchSearch();
                }
              );
            }}
            value={this.state.query}
          />
          <i className="lni lni-search-alt search-icon" />
        </div>
        <div className="search-container">
          <h5 className="mb-3">
            Search Results
            <hr />
          </h5>

          {(productsData?.length || advertisersData?.length) ? (
            <>
              <div className="d-flex flex-row flex-wrap w-full justify-content-around">
                <label className={`btn btn-sm text-truncate btn-outline-secondary ${this.state.filter === 7 ? 'active' : ''}`} onClick={() => this.filter(7)}>Past 7 days</label>
                <label className={`btn btn-sm text-truncate btn-outline-secondary ${this.state.filter === 30 ? 'active' : ''}`} onClick={() => this.filter(30)}>Past 30 days</label>
                <label className={`btn btn-sm text-truncate btn-outline-secondary ${this.state.filter === 90 ? 'active' : ''}`} onClick={() => this.filter(90)}>Past 90 days</label>
                <label className={`btn btn-sm text-truncate btn-outline-secondary ${this.state.filter === 180 ? 'active' : ''}`} onClick={() => this.filter(180)}>Past 180 days</label>
                <label className={`btn btn-sm text-truncate btn-outline-secondary ${this.state.filter === 360 ? 'active' : ''}`} onClick={() => this.filter(360)}>Past 360 days</label>
                <label className={`btn btn-sm text-truncate btn-outline-secondary ${!!!this.state.filter ? 'active' : ''}`} onClick={() => this.filter()}>All</label>
              </div>
            </>
          ) : null}

          <div className="search-results mt-5">
            {productsData?.length || advertisersData?.length ? (
              <div>
                <div>
                  <h6>Products</h6>
                  <div className="mt-4">
                    {displayProducts.map((item, key) => {
                      return (
                        <div
                          className="card p-3 border-0 mb-2"
                          key={item.uuid + "-" + key + "-product"}
                        >
                          <div className="row px-3">
                            <div className="col-sm-6 px-0">
                              <a
                                href={`/products/${encodeURIComponent(
                                  item.product
                                )}?since=${this.product_start_date(
                                  item
                                )}&till=${this.product_end_date(item)}&sortBy=${this.state.sortBy
                                  }`}
                              >
                                {item.product}
                              </a>
                            </div>
                            <div className="col-sm-2 px-0">
                              {moment(item.start_date).format("ll")}
                            </div>
                            <div className="col-sm-2 px-0">
                              {item.status === 0 ? (
                                moment(item.end_date).format("ll")
                              ) :
                                `Active`
                              }
                            </div>
                            <div className="col-sm-2 px-0">
                              {item.count}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="mt-5">
                  <h6>Advertisers</h6>
                  <div className="mt-4">
                    {advertisersData.map((item, key) => {
                      return (
                        <div
                          className="card p-3 border-0 mb-2"
                          key={item + "-" + key + "-advertiser"}
                        >
                          <div className="row px-3">
                            <div className="col-sm-12 px-0">
                              <a
                                href={`/brands/${encodeURIComponent(
                                  item
                                )}?${this.advertiser_date_range()
                                  }&sortBy=${this.state.sortBy}`}
                              >
                                {item}
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center text-muted text-capitalize p-5 m-5">
                {this.state.searching
                  ? "Searching. Please wait..."
                  : "Press Enter or Click on Search Icon"}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Search;
