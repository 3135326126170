import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LineChart from "../charts/line_chart";
import { ResponsiveHeatMap } from "@nivo/heatmap";

// libraries
import moment from "moment";
import qs from "query-string";
import { ApiList } from "../../config/apiList";
import Lookup from "../../config/lookup";
import * as utils from "../common/utils";

import API from "../../config/api";

class OverviewTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: props.sortBy,
            forName: props.forName,
            listData: [],
            xAxisData: [],
            yAxisData: [],
            chartComponent: [],
            heatChartData: [],
            amPmFlag: false,
            //totalCount: "",
            chartKeys: [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
            ],
            innerTabFlag: false,
        };
    }
    componentDidMount = () => {
        let params = qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        });
        this.setState({
            since: params.since
                ? +params.since
                : moment().subtract(5, "day").startOf("day").unix(),
            till: params.till ? +params.till : moment().unix(),
        });
        this.fetchTopData();
    };

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.fetchTopData();
            // this.getStatistics();
            // this.getStatisticsHourly();
        }
    }
    fetchTopData = () => {
        const URL =
            ApiList.env +
            ApiList.endpoint.topList +
            "/" +
            this.props.forName +
            "/top?since=" +
            this.props.since +
            "&till=" +
            this.props.till +
            "&sortBy=" +
            this.props.sortBy +
            "&limit=10";

        API.call(URL).then((data) => {
            this.setState(
                {
                    listData: data,
                },
                () => {
                    this.getStatistics();
                    this.getStatisticsHourly();
                }
            );
        });
    };
    getStatistics = () => {
        var channelList = "";
        this.state.listData.forEach((item) => {
            channelList += encodeURIComponent(item.name) + ",";
        });

        this.setState({ chartComponent: [] });

        const URL =
            ApiList.env +
            ApiList.endpoint.statistics +
            "daily?since=" +
            this.props.since +
            "&till=" +
            this.props.till +
            "&sortBy=" +
            this.props.sortBy +
            "&" +
            this.props.forName +
            "=" +
            encodeURIComponent(channelList);

        API.call(URL).then((data) => {
            let xData = [];
            let yData = [];

            //data.map((item, key) => { Replaced with forEach
            data.forEach((item, key) => {
                if (key < 7) {
                    xData.push(item.count ? item.count : 0);
                    yData.push(
                        item.date ? moment(item.date).format("DD-MM") : 0
                    );
                }
            });

            this.setState({
                chartComponent: [
                    <LineChart
                        key={yData}
                        sortBy={this.props.sortBy}
                        labels={yData}
                        data={xData}
                        color={"#000"}
                    />,
                ],
            });
        });
    };
    getStatisticsHourly = () => {
        var channelList = "";
        this.state.listData.forEach((item) => {
            channelList += encodeURIComponent(item.name) + ",";
        });

        const URL =
            ApiList.env +
            ApiList.endpoint.statistics +
            "dailyhourly?since=" +
            this.props.since +
            "&till=" +
            this.props.till +
            "&sortBy=" +
            this.props.sortBy +
            "&" +
            this.props.forName +
            "=" +
            encodeURIComponent(channelList);

        API.call(URL).then((data) => {
            let monthDays = {};

            data.forEach((item) => {
                const m = moment(item.dateHour);
                let monthDay = m.format("MMM DD");
                let hour = m.hour();

                let monthDayArr = monthDays[monthDay];

                if (!monthDayArr) {
                    monthDayArr = Array(24).fill(0);
                }
                monthDayArr[hour] = item.count;
                // .push({ hour: hour, count: item.count });
                monthDays[monthDay] = monthDayArr;
            });

            const keys = Object.keys(monthDays);
            let result = [];
            // keys.map((key) => { Replaced with foreach
            keys.forEach((key) => {
                const array = monthDays[key];
                let obj = {};
                obj[key] = array.map(function (N, I) {
                    return { hour: I, count: N };
                });
                result.push(obj);
            });

            let convertToHeatData = [];
            let nth = parseInt(result.length / 10) || 1;
            result
                .filter((e, i) => i % nth === nth - 1)
                .forEach((itemValue, i) => {
                    let date = Object.keys(itemValue);
                    let tempHold = {};
                    itemValue[date].forEach((itemhour) => {
                        tempHold = {
                            ...tempHold,
                            [itemhour.hour]: itemhour.count,
                        };
                    });
                    tempHold = { ...tempHold, date: date.toString() };
                    convertToHeatData.push(tempHold);
                });
            this.setState({
                heatChartData: convertToHeatData,
            });
        });
    };

    amPmHandler = (event) => {
        let selected = event.target.value;
        if (selected === "am/pm") {
            this.setState({
                chartKeys: [
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                ],
            });
        } else if (selected === "am") {
            this.setState({
                chartKeys: [
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                ],
            });
        } else if (selected === "pm") {
            this.setState({
                chartKeys: [
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                ],
            });
        }
    };

    render() {
        const { forName, listData } = this.state;
        const theme = {
            axis: {
                fontSize: "14px",
                tickColor: "#eee",
                ticks: {
                    line: {
                        stroke: "#555555",
                    },
                    text: {
                        fill: "#aaaaaa",
                    },
                },
                legend: {
                    text: {
                        fill: "#aaaaaa",
                    },
                },
            },
            grid: {
                line: {
                    stroke: "#555555",
                },
            },
            tooltip: {
                container: {
                    background: "#000000",
                    color: "#FFF",
                },
            },
        };

        return (
            <div className="row">
                <div className="col-xl-4 card-col">
                    <div className="card">
                        <div className="card-header">
                            <h6>Top 10 {Lookup.display[forName] || forName}</h6>
                        </div>
                        <div className="card-body">
                            <div className="row list-group list-group-horizontal">
                                {listData.map((item, key) => (
                                    <a
                                        href={`/${forName}/${encodeURIComponent(
                                            item.name
                                        )}?since=${this.props.since}&till=${this.props.till
                                            }`}
                                        className="col-12 list-group-item border-0"
                                        key={item.name + key}
                                    >
                                        <div className="row" title={item.name}>
                                            <span className="col-9 col-sm-8 text-truncate">
                                                <small className="badge badge-secondary">
                                                    {key + 1}
                                                </small>{" "}
                                                {item.name}
                                            </span>
                                            <span className="col-3 col-sm-4 text-right">
                                                <label className="badge-primary badge badge-pill">
                                                    {utils.formatter(
                                                        this.props.sortBy,
                                                        item.count
                                                    )}
                                                </label>
                                            </span>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="card">
                        <div className="card-header">
                            <h6>{Lookup.display[forName] || forName}</h6>
                            <small className="text-muted">
                                {this.props.sortBy} Charts
                            </small>
                        </div>
                        <div className="card-body">
                            <ul className="nav nav-tabs" role="tablist">
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setState({ amPmFlag: false });
                                    }}
                                >
                                    <a
                                        className="nav-link active"
                                        id="daily-tab"
                                        data-toggle="tab"
                                        href="# "
                                        role="tab"
                                        aria-controls="daily"
                                        aria-selected="true"
                                    >
                                        daily
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.setState({ amPmFlag: true });
                                    }}
                                >
                                    <a
                                        className="nav-link"
                                        id="hourly-tab"
                                        data-toggle="tab"
                                        href="# "
                                        role="tab"
                                        aria-controls="hourly"
                                        aria-selected="false"
                                    >
                                        hourly
                                    </a>
                                </li>
                                {this.state.amPmFlag ? (
                                    <li className="nav-item ml-auto">
                                        <div className="custom-select-container mr-5">
                                            <select
                                                className="custom-select custom-select-input"
                                                onChange={this.amPmHandler}
                                            >
                                                <option value="am/pm">
                                                    AM/PM
                                                </option>
                                                <option value="am">AM</option>
                                                <option value="pm">PM</option>
                                            </select>
                                        </div>
                                    </li>
                                ) : null}
                            </ul>
                            <div className="tab-content">
                                {this.state.amPmFlag === false ? (
                                    <div
                                        className="tab-pane fade show active"
                                        role="tabpanel"
                                        aria-labelledby="daily-tab"
                                    >
                                        <div
                                            style={{ height: "300px" }}
                                            className="w-100"
                                        >
                                            {this.state.chartComponent}
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="tab-pane fade show active w-100"
                                        role="tabpanel"
                                        aria-labelledby="hourly-tab"
                                        style={{ height: "400px" }}
                                    >
                                        <ResponsiveHeatMap
                                            data={this.state.heatChartData}
                                            keys={this.state.chartKeys}
                                            indexBy="date"
                                            margin={{
                                                top: 0,
                                                right: 0,
                                                bottom: 50,
                                                left: 50,
                                            }}
                                            colors="reds"
                                            borderColor={{
                                                from: "color",
                                                modifiers: [
                                                    ["darker", 0.6],
                                                    ["opacity", 0.5],
                                                ],
                                            }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                orient: "bottom",
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legendPosition: "middle",
                                                legendOffset: 36,
                                            }}
                                            axisLeft={{
                                                orient: "left",
                                                tickSize: 5,
                                                tickPadding: 0,
                                                tickRotation: 0,
                                                legend: "",
                                                legendPosition: "middle",
                                                legendOffset: -40,
                                            }}
                                            cellOpacity={1}
                                            enableLabels={false}
                                            labelTextColor={{
                                                from: "color",
                                                modifiers: [["darker", 1.8]],
                                            }}
                                            defs={[
                                                {
                                                    id: "lines",
                                                    type: "patternLines",
                                                    background: "inherit",
                                                    color: "rgba(0, 0, 0, 0.1)",
                                                    rotation: -45,
                                                    lineWidth: 4,
                                                    spacing: 7,
                                                },
                                            ]}
                                            fill={[{ id: "lines" }]}
                                            animate={true}
                                            motionStiffness={80}
                                            theme={theme}
                                            motionDamping={9}
                                            hoverTarget="cell"
                                            cellHoverOthersOpacity={0.25}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(OverviewTab);
